// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-aspiration-js": () => import("./../src/pages/about/aspiration.js" /* webpackChunkName: "component---src-pages-about-aspiration-js" */),
  "component---src-pages-about-background-js": () => import("./../src/pages/about/background.js" /* webpackChunkName: "component---src-pages-about-background-js" */),
  "component---src-pages-about-eddie-js": () => import("./../src/pages/about/eddie.js" /* webpackChunkName: "component---src-pages-about-eddie-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-kongsir-js": () => import("./../src/pages/about/kongsir.js" /* webpackChunkName: "component---src-pages-about-kongsir-js" */),
  "component---src-pages-ad-js": () => import("./../src/pages/ad.js" /* webpackChunkName: "component---src-pages-ad-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-demos-js": () => import("./../src/pages/learn/demos.js" /* webpackChunkName: "component---src-pages-learn-demos-js" */),
  "component---src-pages-learn-glossary-js": () => import("./../src/pages/learn/glossary.js" /* webpackChunkName: "component---src-pages-learn-glossary-js" */),
  "component---src-pages-learn-index-js": () => import("./../src/pages/learn/index.js" /* webpackChunkName: "component---src-pages-learn-index-js" */),
  "component---src-pages-learn-lessons-js": () => import("./../src/pages/learn/lessons.js" /* webpackChunkName: "component---src-pages-learn-lessons-js" */),
  "component---src-pages-reactions-index-js": () => import("./../src/pages/reactions/index.js" /* webpackChunkName: "component---src-pages-reactions-index-js" */),
  "component---src-pages-reactions-letters-js": () => import("./../src/pages/reactions/letters.js" /* webpackChunkName: "component---src-pages-reactions-letters-js" */),
  "component---src-pages-reactions-media-js": () => import("./../src/pages/reactions/media.js" /* webpackChunkName: "component---src-pages-reactions-media-js" */)
}

